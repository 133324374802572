<template>
  <div class="integrationCenter" v-loading="appLoad">
    <div class="applicationList">
      <div class="applications clearfix" v-if="cloudCertAPI.length>0">
        <h2>云证书API集成服务</h2>
        <dl v-for="(item,index) of cloudCertAPI" :key="index" @click="showDetails(item.orderNo,'cloudCertAPI')">
          <dt>{{item.productName}}</dt>
          <dd><el-button type="text">查看接口文档</el-button></dd>
        </dl>
      </div>
      <div class="applications" v-if="identityRealName.length>0">
        <h2>网络身份实名认证集成服务</h2>
        <dl v-for="(item,index) of identityRealName" :key="index" @click="showDetails(item.customerProductId,'identityRealName')">
          <dt>{{item.productName}}</dt>
          <dd><span>总次数：</span>{{item.totalTimes}}次</dd>
          <dd><span>剩余次数：</span>{{item.availableTimes}}次</dd>
          <dd><el-button type="text">查看接口文档</el-button></dd>
          <dd><el-button type="primary" size="small" @click.stop="continuation(item.productId,item.appId)">续次</el-button></dd>
        </dl>
      </div>
      <div class="notIntegrated" v-if="cloudCertAPI.length===0&&identityRealName.length===0">
        <dl>
          <dt><img src="@/assets/image/personCenter/emptyState.png"></dt>
          <dd>未有集成的集成业务,前往 <router-link :to="{name:'businessCooperation'}">业务合作</router-link> 集成</dd>
        </dl>
      </div>
    </div>
    <integrationDetails ref="orderDetails" v-if="detailsShow" @hideDetails="hideDetails" />
  </div>
</template>

<script>
import { orderStatus } from '@/utils/typeMatch';
import integrationDetails from './integrationDetails.vue';
export default {
  data () {
    return {
      appLoad: false,
      detailsShow: false,
      cloudCertAPI: [],
      identityRealName: []
    }
  },
  components: {
    integrationDetails
  },
  filters: {
    orderStatus
  },
  mounted () {
    this.getCloudList()
    this.getNetworkList()
  },
  methods: {
    showDetails (orderNo, orderType) {
      this.detailsShow = true
      this.$nextTick(() => {
        this.$refs.orderDetails.getDetails(orderNo, orderType)
      })
    },
    hideDetails () {
      this.detailsShow = false
    },
    getCloudList () {
      this.appLoad = true
      this.$api.getCloudList().then((res) => {
        this.appLoad = false
        this.cloudCertAPI = res.data
      }).catch(() => {
        this.appLoad = false
      })
    },
    getNetworkList () {
      this.appLoad = true
      this.$api.getNetworkList().then((res) => {
        this.appLoad = false
        this.identityRealName = res.data
      }).catch(() => {
        this.appLoad = false
      })
    },
    continuation (productId, appId) {
      this.$store.commit('user/setOrderNo', '')
      this.$store.commit('user/seAppId', appId)
      this.$router.push({ name: 'identityAuth', query: { productId: productId, buyType: 2 } })
    }
  },
}
</script>

<style lang="scss" scoped>
.integrationCenter {
  display: flex;
  width: 1300px;
  margin: 0 auto;
  min-height: 100%;
}
.applicationList {
  width: 100%;
  background: #fff;
  padding: 0 32px;
  margin-bottom: 24px;
  h2 {
    font-size: 18px;
    font-weight: bold;
    color: #303b50;
    line-height: 24px;
    margin-bottom: 24px;
  }
}
.applications {
  width: 100%;
  padding-top: 32px;
  border-bottom: 1px solid #dee2e8;
  &:last-child {
    border: none;
  }
  dl {
    width: 262px;
    min-height: 120px;
    background: #f1f4fc;
    border-radius: 4px;
    padding: 24px 16px;
    margin-bottom: 32px;
    margin-right: 40px;
    float: left;
    cursor: pointer;
    position: relative;
    // &:nth-child(4n) {
    //   margin-right: 0;
    // }
  }
  dt {
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    font-weight: bold;
    color: #303b50;
  }
  dd {
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    margin-top: 14px;
    cursor: pointer;
  }
  span {
    display: inline-block;
    width: 62px;
    color: #b0b4bd;
  }
  .el-button.el-button--text {
    padding: 0;
  }
  .el-button.el-button--primary {
    display: inline-block;
    width: 62px;
    height: 30px;
    background: #1c67f3;
    border-radius: 4px;
    font-size: 14px;
    padding: 7px 15px;
    color: #ffffff;
    text-align: center;
    position: absolute;
    bottom: 16px;
    right: 16px;
  }
}
.notIntegrated {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px 24px 90px;
  dl {
    text-align: center;
  }
  img {
    width: 60px;
  }
  dd {
    margin-top: 16px;
    font-size: 12px;
    font-weight: 400;
    color: #b0b4bd;
    line-height: 18px;
  }
}
</style>
