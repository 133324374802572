<template>
  <div class="detailsBox" v-loading="integrationLoad">
    <el-scrollbar>
      <div class="commonMainArea documentAndExample">
        <div class="returnCrumbs">
          <el-button type="text" class="crumbsActive" @click="closeDetails"><i class="el-icon-arrow-left"></i>应用列表</el-button>
        </div>
        <div class="integrationDetails" v-if="orderDetail.status<4||orderDetail.status===5">
          <div class="integrationServiceIllustrate">
            <h2>{{orderDetail.productName}}</h2>
            <p><span>API介绍：</span>{{orderDetail.productDescription}}</p>
          </div>
          <div class="integrationServiceInfo">
            <h2>业务申请</h2>
            <div class="applyInfo" v-if="orderTypes === 'cloudCertAPI'">
              <p><span>认证账号：</span>{{orderDetail.appId}}</p>
              <p><span>申请主体：</span>{{orderDetail.companyName}}</p>
              <p><span>订单号：</span>{{orderDetail.orderNo}}</p>
              <p><span>申请时间：</span>{{orderDetail.createTime}}</p>
              <p><span>业务状态：</span><u :class="'orderStatus'+orderDetail.status">{{orderDetail.status|orderStatus}}</u></p>
              <p class="wholeLine"><span>接口权限：</span><i v-for="item of apiNameList" :key="item">{{item}}</i></p>
              <p class="wholeLine"><span>审核时间：</span>{{orderDetail.operationTime||'- -'}}</p>
              <p class="wholeLine"><span>审核意见：</span>{{orderDetail.operationComment}}</p>
            </div>
            <div class="orderInfo" v-else>
              <p>认证账号：<span>{{orderDetail.appId}}</span></p>
              <p class="wholeLine">申请主体：<span>{{orderDetail.companyName}}</span></p>
              <p>订单号：<span>{{orderDetail.orderNo}}</span></p>
              <p>订单状态：<span :class="'orderStatus orderStatus'+orderDetail.status">{{orderDetail.status|orderStatus}}</span></p>
              <p>支付方式：<span>{{orderDetail.payWay|payWay}}</span></p>
              <p>订单类型：<span>{{orderDetail.type|orderType}}</span></p>
              <p>订单创建时间：<span>{{orderDetail.createTime}}</span></p>
              <p>支付时间：<span>{{orderDetail.payTime || '- -'}}</span></p>
              <p>订单金额：<span v-if="orderDetail.price">￥{{orderDetail.price}}</span><span v-else>- -</span></p>
            </div>
          </div>
        </div>
        <div class="apiContent" v-else>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="API文档" name="documentation">
              <div class="apiInfo">
                <div class="integrationServiceIllustrate">
                  <h2>{{orderDetail.productName}}</h2>
                  <p><span>API介绍：</span>{{orderDetail.productDescription}}</p>
                </div>
                <div class="integrationServiceIllustrate">
                  <p v-if="orderTypes==='cloudCertAPI'"><span>订单号：</span>{{orderDetail.orderNo}}</p>
                  <p v-if="orderTypes==='cloudCertAPI'" class="limitsOfAuthority"><span>接口权限：</span><u v-for="item of apiNameList" :key="item">{{item}}</u></p>
                  <p v-else class="limitsOfAuthority"><span>接口权限：</span><u>{{orderDetail.productName || '- -'}}</u></p>
                  <p v-if="orderDetail.times"><span>剩余次数：</span><i>{{orderDetail.times}}次</i></p>
                  <p><span>认证账号：</span>{{orderDetail.appId}}</p>
                  <p><span>申请主体：</span>{{orderDetail.companyName}}</p>
                </div>
                <div class="apiDescribe">
                  <div v-if="apiDocument.length>1">
                    <el-button plain size="small" v-for="(item,index) of apiDocument" :key="index" :type="documentIndex===index?'primary':''" @click="documentIndex = index">{{item.name}}</el-button>
                  </div>

                  <p><span>调用地址：</span>{{apiDocument[documentIndex]&&apiDocument[documentIndex].requestAddress}}</p>
                  <p><span>请求方式：</span>{{apiDocument[documentIndex]&&apiDocument[documentIndex].requestMethod}}</p>
                  <p class="hasBorder"><span>支持格式：</span>{{apiDocument[documentIndex]&&apiDocument[documentIndex].dataFormat}}</p>
                  <h2>请求参数：</h2>
                  <el-table ref="tableList1" :data="apiDocument[documentIndex]&&apiDocument[documentIndex].requestParam" style="width: 100%;">
                    <el-table-column prop="name" label="名称" width="200"></el-table-column>
                    <el-table-column prop="type" label="类型" width="200"></el-table-column>
                    <el-table-column prop="isMust" label="是否必须" width="200"></el-table-column>
                    <el-table-column prop="example" label="示例值" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="description" label="说明" width="300" show-overflow-tooltip></el-table-column>
                  </el-table>
                  <h2 class="hasMargin">正确返回：</h2>
                  <pre>{{apiDocument[documentIndex]&&apiDocument[documentIndex].trueReturn}}</pre>
                  <h2 class="hasMargin">错误返回：</h2>
                  <pre>{{apiDocument[documentIndex]&&apiDocument[documentIndex].errorReturn}}</pre>
                  <h2 class="hasMargin">返回字段说明：</h2>
                  <el-table ref="tableList1" :data="apiDocument[documentIndex]&&apiDocument[documentIndex].feildReturn" style="width: 100%;">
                    <el-table-column prop="field" label="字段名" width="300"></el-table-column>
                    <el-table-column prop="description" label="说明" min-width="100" show-overflow-tooltip></el-table-column>
                  </el-table>
                  <h2 class="hasMargin">code错误码说明：</h2>
                  <el-table ref="tableList1" :data="apiDocument[documentIndex]&&apiDocument[documentIndex].codeReturn" style="width: 100%;">
                    <el-table-column prop="code" label="code" width="300"></el-table-column>
                    <el-table-column prop="description" label="说明" min-width="100" show-overflow-tooltip></el-table-column>
                  </el-table>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="示例代码" name="sample">
              <div class="sampleCode">
                <el-button plain size="small" type="primary">Java</el-button>
                <div v-for="(item,index) of apiDocument" :key="index">
                  <h2>{{item.name}}</h2>
                  <pre>{{apiDocument[documentIndex]&&apiDocument[codeIndex].exampleCode}}</pre><code class="lang-javascript"></code>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="嵌入页面风格配置" name="styleConfig" v-if="orderTypes==='cloudCertAPI'">
              <div class="styleConfigBox clearfix">
                <div class="pageEffects">
                  <div class="pageStyleBox">
                    <div class="pageStyleHead">
                      <img :src="pageLogo">
                      <h2>{{titleTxt}}</h2>
                    </div>
                    <div class="pageStyleMain">
                      <div class="pageStyleReturn">
                        <el-button type="text" class="crumbsActive" :style="{'color':themeColors}"><i class="el-icon-arrow-left"></i>{{returnTxt}}</el-button>
                      </div>
                      <div class="pageStyleImg">
                        <img src="@/assets/image/personCenter/pageContent.png">
                      </div>
                      <div class="pageStyleFooter">
                        <el-button size="small" plain :style="{'color':themeColors,'borderColor':themeColors}">次要按钮</el-button>
                        <el-button size="small" type="primary" :style="{'backgroundColor':themeColors,'borderColor':themeColors}">主要按钮</el-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="styleConfig">
                  <h5>嵌入页面风格配置</h5>
                  <p>
                    <span>图标</span>
                    <el-upload :action="uploadIcon" :headers="myHeader" :on-success="handleSuccess" :before-upload="beforeUpload" :show-file-list="false" accept="image/png,image/jpg,image/jpeg">
                      <el-button type="primary" plain size="small">上传</el-button>
                    </el-upload>
                  </p>
                  <p>图标大小&lt;=2MB，格式要求png /jpg/jpeg</p>
                  <p>
                    <span>主题颜色</span>
                    <label>
                      <span class="colorValue">{{themeColors}}</span>
                      <el-color-picker v-model="themeColors"></el-color-picker>
                    </label>
                  </p>
                  <p><span>标题文案</span><el-input v-model="titleTxt" placeholder="请输入标题文案"></el-input></p>
                  <p><span>返回文案</span><el-input v-model="returnTxt" placeholder="请输入返回文案"></el-input></p>
                  <div class="styleConfigFooter">
                    <el-button size="small" @click="resetting">重置</el-button>
                    <el-button size="small" type="primary" @click="savePageStyle">保存</el-button>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { orderStatus, orderType, productSpecs, productType, cloudCertType, payWay } from '@/utils/typeMatch';
export default {
  data () {
    return {
      integrationLoad: false,
      orderNo: '',
      orderDetail: {},
      apiNameList: [],
      activeName: 'documentation',
      parameters: [
        {
          name: 'name',
          type: 'string',
          required: 'true',
          example: '姓名'
        },
        {
          name: 'idcard',
          type: 'varchar',
          required: 'true',
          example: '身份证号码'
        },
        {
          name: 'appid',
          type: 'varchar',
          required: 'true',
          example: '服务商分配的唯一标识'
        },
        {
          name: 'timestamp',
          type: 'number',
          required: 'true',
          example: '当前时间的毫秒数'
        }
      ],
      returnField: [
        {
          fieldName: 'success',
          illustrate: '接口请求成功标识，true为成功，false为失败，失败情况下，会有对应描述和状态弱'
        },
        {
          fieldName: 'code',
          illustrate: '成功为200，其它为失败状态码'
        }
      ],
      errorCode: [
        {
          code: '200',
          illustrate: '成功'
        },
        {
          code: '400',
          illustrate: '参数错误'
        },
      ],
      themeColors: '#1C67F3',
      titleTxt: '个人中心',
      returnTxt: '列表',
      pageLogo: require('@/assets/image/personCenter/certLogo.png'),
      orderTypes: '',
      apiDocument: [],
      uploadIcon: '',
      myHeader: {
        Authorization: sessionStorage.getItem('token')
      },
      iconBase64: '',
      documentIndex: 0,
      codeIndex: 0
    }
  },
  filters: {
    orderType,
    productType,
    orderStatus,
    productSpecs,
    cloudCertType,
    payWay
  },
  components: {
  },
  mounted () {
    this.uploadIcon = this.CONFIG.baseURL + '/portal/page/upload-icon'
  },
  methods: {
    handleClick (tab) {
      this.documentIndex = 0
      this.codeIndex = 0
      if (tab.name === 'styleConfig') {
        this.getPageConfig()
      }
    },
    closeDetails () {
      this.documentIndex = 0
      this.$emit('hideDetails')
    },
    getDetails (orderNo, orderType) {
      this.orderTypes = orderType
      let fn
      if (orderType === 'cloudCertAPI') {
        fn = this.$api.getIntegrationDetail({ orderNo: orderNo })
      } else {
        fn = this.$api.getIdentifyDetail({ customerProductId: orderNo })
      }
      this.integrationLoad = true
      fn.then((res) => {
        this.integrationLoad = false
        this.orderDetail = res.data
        this.apiDocument = res.data.apiDocuments || []
        if (res.data.apiNameList) {
          this.apiNameList = res.data.apiNameList
        }
      }).catch((err) => {
        this.integrationLoad = false
        if (err.code !== '0') {
          this.$parent.detailsShow = false
        }
      })
    },
    resetting () {
      this.themeColors = '#1C67F3'
      this.titleTxt = '个人中心'
      this.returnTxt = '列表'
      this.pageLogo = require('@/assets/image/personCenter/certLogo.png')
      this.iconBase64 = 'https://yhca-pro-public.oss-cn-hangzhou.aliyuncs.com/12bc241cf7054e208a43ad328c38246c.png'
    },
    handleSuccess (res) {
      if (res.code) {
        this.iconBase64 = res.data.ossUrl
        this.pageLogo = res.data.ossUrl
      } else {
        this.pageLogo = require('@/assets/image/personCenter/certLogo.png')
        this.$message.error(res.msg)
      }
    },
    beforeUpload (file) {
      const isIMG =
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isIMG) {
        this.$message.error('上传头像图片只能是 png/jpg/jpeg 格式!')
        return false
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
        return false
      }
    },
    savePageStyle () {
      this.integrationLoad = true
      let data = {
        appId: this.orderDetail.appId,
        icon: this.iconBase64,
        themeColor: this.themeColors,
        titleText: this.titleTxt,
        returnText: this.returnTxt
      }
      this.$api.pageSaveOrUpdate(data).then((res) => {
        this.integrationLoad = false
        if (res.code === '0') {
          this.$message({
            message: res.msg,
            type: 'success'
          });
        }
      }).catch(() => {
        this.integrationLoad = false
      })
    },
    getPageConfig () {
      this.integrationLoad = true
      this.$api.pageByAppid({ appId: this.orderDetail.appId }).then((res) => {
        this.integrationLoad = false
        if (res.code === '0') {
          this.pageLogo = res.data.icon
          this.iconBase64 = res.data.icon
          this.themeColors = res.data.themeColor
          this.returnTxt = res.data.returnText
          this.titleTxt = res.data.titleText
        } else {
          this.resetting()
        }
      }).catch(() => {
        this.integrationLoad = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detailsBox {
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0px;
  right: 0px;
  z-index: 99;
  display: flex;
  background: #f1f3f7;
  ::v-deep .el-scrollbar {
    width: 100%;
  }
  ::v-deep .el-scrollbar__wrap {
    height: calc(100% - 16px);
  }
  ::v-deep .el-scrollbar__view {
    width: 1300px;
    margin: 16px auto 0;
  }
}
.returnCrumbs {
  padding: 8px 24px;
}
.el-tab-pane {
  .el-button--primary.is-plain {
    background: #fff;
    ::v-deep span {
      color: $primaryColor;
    }
  }
}
.noContent {
  padding-top: 120px;
  text-align: center;
  dd {
    margin: 24px 0 42px;
  }
}

.integrationDetails {
  padding: 0 32px;
}
.integrationServiceIllustrate {
  padding: 24px 16px 16px;
  background: #f1f4fc;
  border-radius: 4px;
  margin-bottom: 24px;
  h2 {
    font-size: 16px;
    color: #303b50;
    line-height: 21px;
    margin-bottom: 16px;
  }
  p {
    font-size: 14px;
    color: #303b50;
    line-height: 24px;
    margin-bottom: 20px;
    &:last-child {
      margin: 0;
    }
  }
  .limitsOfAuthority {
    margin-bottom: 12px;
  }
  span {
    color: #6c737b;
  }
  u {
    display: inline-block;
    height: 24px;
    padding: 0 8px;
    background: #dbe4ff;
    border-radius: 1px;
    border: 1px solid #a9beff;
    font-size: 12px;
    color: #1c67f3;
    line-height: 24px;
    text-align: center;
    text-decoration: none;
    margin-right: 8px;
    margin-bottom: 8px;
  }
  i {
    font-size: 14px;
    color: #1c67f3;
    line-height: 19px;
  }
}
.integrationServiceInfo {
  padding: 24px 16px;
  background: #f1f4fc;
  border-radius: 4px;
  h2 {
    font-size: 16px;
    color: #303b50;
    line-height: 21px;
    margin-bottom: 24px;
  }
}
.applyInfo {
  p {
    display: inline-block;
    margin-bottom: 18px;
    font-size: 14px;
    color: #303b50;
    line-height: 24px;
    vertical-align: top;
    &:nth-child(2n + 1) {
      width: 30%;
    }
    &:nth-child(2n) {
      width: 70%;
    }
    &:nth-last-child(3) {
      margin-bottom: 14px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  p.wholeLine {
    width: 100%;
  }
  span {
    color: #6c737b;
  }
  u {
    display: inline-block;
    height: 24px;
    padding: 0 13px;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    border-radius: 15px;
    text-decoration: none;
  }
  .orderStatus1,
  .orderStatus2 {
    color: #e89e1c;
    background: #fef8ec;
    border: 1px solid #fbbc4d;
  }
  .orderStatus3,
  .orderStatus4 {
    color: #52c41a;
    background: #eff9eb;
    border: 1px solid #d4f0c8;
  }
  .orderStatus5 {
    color: #e34d59;
    background: #ffeeee;
    border: 1px solid #f7caca;
  }
  i {
    display: inline-block;
    width: auto;
    height: 24px;
    background: #dbe4ff;
    border-radius: 1px;
    border: 1px solid #a9beff;
    padding: 4px 8px;
    font-size: 12px;
    color: #1c67f3;
    line-height: 16px;
    margin-right: 8px;
    margin-bottom: 8px;
  }
}
.apiContent {
  padding-top: 3px;
  ::v-deep .el-tabs__header {
    padding: 0 24px;
    margin: 0 0 1px;
  }
}
.apiInfo {
  padding: 24px 32px;
}
.apiDescribe {
  .el-button.is-plain {
    margin-bottom: 24px;
  }
  p {
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    padding-bottom: 24px;
  }
  .hasBorder {
    border-bottom: 1px solid #dee2e8;
  }
  h2 {
    font-weight: normal;
    font-size: 14px;
    position: relative;
    padding: 24px 0 24px 12px;
    line-height: 19px;
    color: #303b50;
    &::before {
      content: '';
      width: 2px;
      height: 14px;
      position: absolute;
      top: 26px;
      left: 0;
      background: $primaryColor;
    }
  }
  .hasMargin {
    margin-top: 16px;
  }
  ::v-deep .el-table tr > th {
    background: #f7faff;
    padding: 9px 0;
    color: #6c737b;
    font-weight: normal;
    border-color: #dee0e9;
  }
  ::v-deep .el-table td {
    padding: 13px 0;
    border-color: #dee0e9;
  }
  pre {
    padding: 16px;
    margin: 0;
    background: #f2f3f9;
  }
}
.sampleCode {
  padding: 32px 32px;
  ::v-deep .el-button {
    line-height: 12px;
    margin-bottom: 24px;
  }
  h2 {
    font-size: 14px;
  }
  pre {
    padding: 16px;
    margin: 24px 0;
    background: #f2f3f9;
  }
}

.pageEffects {
  width: 980px;
  height: 612px;
  padding: 76px 110px 76px 110px;
  background: #ebebec;
  float: left;
}
.pageStyleBox {
  width: 100%;
  height: 100%;
  background: #f8f8f9;
}
.pageStyleHead {
  width: 758px;
  height: 44px;
  padding: 0 6px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(45, 50, 54, 0.1);
  display: flex;
  align-items: center;
  img {
    width: 89px;
    height: 40px;
    margin-right: 16px;
  }
  h2 {
    display: inline-block;
    font-size: 14px;
    color: #303b50;
    line-height: 40px;
    text-shadow: 0px 4px 8px rgba(45, 50, 54, 0.1);
    padding-left: 16px;
    font-weight: normal;
    position: relative;
    &::before {
      content: '';
      width: 1px;
      height: 26px;
      background: #d8d8d8;
      position: absolute;
      top: 7px;
      left: 0;
    }
  }
}
.pageStyleMain {
  height: calc(100% - 56px);
  margin-top: 12px;
  padding: 18px 20px 63px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(45, 50, 54, 0.1);
  position: relative;
}
.pageStyleReturn {
  .el-button {
    font-size: 12px;
    color: #1c67f3;
    line-height: 16px;
    padding: 0;
  }
}
.pageStyleImg {
  padding-top: 44px;
  text-align: center;
  img {
    width: 366px;
  }
}
.pageStyleFooter {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 63px;
  .el-button {
    line-height: 16px;
  }
  ::v-deep .el-button.is-plain {
    border-color: #1c67f3;
    margin-right: 6px;
    color: #1c67f3;
    span {
      color: unset;
    }
  }
}
.styleConfig {
  width: 320px;
  height: 612px;
  background: #ffffff;
  position: relative;
  float: left;
  h5 {
    height: 42px;
    background: #dfe3fc;
    font-size: 14px;
    color: #303b50;
    line-height: 42px;
    padding-left: 16px;
    font-weight: normal;
    margin-bottom: 12px;
  }
  p {
    padding: 0 16px;
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    &:nth-child(2) {
      margin-bottom: 23px;
    }
    > span {
      display: inline-block;
      flex-shrink: 0;
      width: 58px;
    }
  }
  .el-button {
    width: 88px;
    line-height: 12px;
  }
  .el-input {
    margin-left: 8px;
  }
  ::v-deep .el-input__inner {
    height: 36px;
    font-size: 14px;
    color: #6c737b;
    line-height: 36px;
  }
  label {
    position: relative;
    height: 42px;
  }
  ::v-deep .el-color-picker__trigger {
    width: 118px;
    height: 42px;
    padding: 4px;
    border-color: #dee2e8;
  }
  ::v-deep .el-color-picker__color {
    width: 32px;
    float: right;
    border: none;
  }
  ::v-deep .el-color-picker__icon {
    width: 30px;
    left: 96px;
  }
  .colorValue {
    position: absolute;
    top: 12px;
    left: 14px;
  }
}
.styleConfigFooter {
  width: 100%;
  padding: 16px 0;
  text-align: center;
  position: absolute;
  bottom: 0;
  ::v-deep .el-button {
    width: 60px;
  }
}

.orderInfo {
  p {
    width: 33.3%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-bottom: 24px;
    font-size: 14px;
    color: #6c737b;
    line-height: 19px;
    cursor: default;
    &:last-child {
      padding-bottom: 0;
    }
  }
  p.wholeLine {
    width: 66%;
  }
  span {
    color: #303b50;
  }
  .orderStatus {
    display: inline-block;
    height: 24px;
    padding: 0 8px;
    font-weight: normal;
    line-height: 22px;
    border-radius: 15px;
    &.orderStatus1,
    &.orderStatus2 {
      color: #e89e1c;
      background: #fef8ec;
      border: 1px solid #fbbc4d;
    }
    &.orderStatus3,
    &.orderStatus4 {
      color: #52c41a;
      background: #eff9eb;
      border: 1px solid #d4f0c8;
    }
  }
}
</style>
